/**
 * templates/events.js --- Template for all unique event pages
 *
 * This file is a template for generating event pages for
 * each unique event, through the createPages function
 * in gatsby-node.js
 */

import React from "react"
import { graphql } from "gatsby"
import EventList from "../components/events/event-list.js"
import EventInfo from "../components/events/event-info.js"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

/**
 * ql_events: Contains the list of all Wet Ink events
 * plus the individual event descriptions based on
 * page slug
 */
export const ql_events = graphql`
  query($slug: String!) {
    # List of events displayed on the left side of the page
    allContentfulEvent(sort: { fields: sortingDate, order: DESC }) {
      edges {
        node {
          shortTitle # Short title
          slug # Event slug
          season # Season number
          sortingDate # Date for sorting
          image {
            file {
              url # Image URL
            }
          }
        }
      }
    }

    # Info for individual events based on slug
    contentfulEvent(slug: { eq: $slug }) {
      title # Event title
      date # Event Date/Time
      venue # Event venue name
      address # Address to venue
      season # Season number
      price # Admission price
      info {
        # Event description
        json
      }
    }
  }
`
/**
 * Events (Default): Component for the content
 * of the event page
 */
const Events = props => {
  return (
    <Layout>
      <SEO title="Events" />
      <EventList season={props.data.contentfulEvent.season} eventData={props.data.allContentfulEvent.edges} />
      <EventInfo eventData={props.data.contentfulEvent} />
    </Layout>
  )
}

export default Events
